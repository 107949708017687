import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/en"
import Seo from "../../components/seo"
import SimplePage from "../../templates/simple-page"
import Features from "../../components/features"
import Link from "../../components/link"
import BackLink from "../../components/back-link"
import Download from "../../components/download"

const DeliveryProgramPage = () => {
  const { fileNode, image } = useStaticQuery(
    graphql`
      query {
        image: file(
          relativePath: { glob: "delivery-program-forming-tools/*.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNode: sanityDownload(
          file: {en: {fileName: {eq: "Finkernagel delivery programme forming tools"}}}
        ) {
          id
          title {
            en
          }
          file {
            en {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Delivery programme forming tools" />
      <SimplePage
        title="Delivery programme forming tools"
        prefix={<BackLink to="/forming-tools" />}
      >
        <Download extension={fileNode.file.en.file.asset.extension}
                fileName={`/static/${fileNode.file.en.fileName}.${fileNode.file.en.file.asset.extension}`} 
                key={fileNode.id} 
                title="Download delivery programme"
                size={fileNode.file.en.file.asset.size}/>
                
        <h2 className="text-black">Our products include:</h2>

        <Features
          items={[
            "Complete tool sets for multi-stage presses",
            "Series tools, tool repairs, reshaping and reworking, custom production, full service",
            "Machine components including punches and die holders",
            "Individual tool components made of carbide or steel",
            "Trim dies",
            "Knock out pins",
            "Extrusion punches",
            "Cold extrusion tools",
            "Dies (round, profiled)",
            "Cutoff knives",
            "Segmented dies",
            "Pre and final punches",
            "Auxiliary tools: Feed rollers, handling jaws and grippers",
            "Machine components in accordance with drawings and samples",
            "Kneading tools",
          ]}
        />

        <h2 className="text-black">Tool sizes</h2>
        <Features
          items={[
            "Outside diameter: up to Ø 250 mm",
            "Lengths: up to 500 mm (turned parts: up to 1,000 mm)",
            "Holes: from Ø 1 mm up to 200 mm",
          ]}
        />

        <h2 className="text-black">Die casing</h2>
        <Features
          items={[
            "Carried out to customer specifications and according to our calculations.",
            "We offer all current coatings as per requirement.",
          ]}
        />

        <Link to="/contact#forming-tools" className="accessory">
          Contact us
        </Link>

        {image && (
          <figure className="-mb-2 mt-12">
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
          </figure>
        )}
      </SimplePage>
    </Layout>
  )
}

export default DeliveryProgramPage
